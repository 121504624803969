export const useCurrentSite = async () => {
  const currentSite = useState("currentSite", () => null)
  const loading = useState("currentSiteLoading", () => false)
  const error = useState("currentSiteError", () => null)

  // Ensure we only use `useRequestHeaders` on the server
  let origin
  if (import.meta.server) {
    const headers = useRequestHeaders(["host"])
    origin = headers?.host
  } else if (import.meta.client) {
    origin = window.location.host // Fallback for client-side navigation
  }

  if (!currentSite.value && !loading.value) {
    loading.value = true
    try {
      const { data } = await useApi("/api/sites", {
        headers: {
          "Content-Type": "application/json",
          Origin: origin,
        },
      })
      if (data?.value) {
        currentSite.value = data.value
      }
    } catch (err) {
      error.value = err.message || "Failed to load site"
      console.error(error.value)
    } finally {
      loading.value = false
    }
  }

  return { currentSite, loading, error }
}
